/*azul:rgb(27, 45, 81)
amarelo:rgb(255, 167, 0);*/

/*
.table {
  padding: 20px;
  background-color: whitesmoke;
  justify-items: center;
  align-items: center;
  border-spacing: 10px;
}
*/

.grid {
  align-self: center;
  display: grid;
  background-color: whitesmoke;
  font-size: 13px;
  grid-template-areas: 'header' 'main' 'footer';
  grid-template-columns: 100%;
  width: 90%;
  min-width: 320px;
}

.header {
  grid-area: header;
  margin-bottom: 10px;
}

.main {
  grid-area: main
}

.header, .inputs {
  display: grid;
  grid-template-columns: 20% 38% 10% 13% 15%;
  grid-gap: 1%;
  width: calc(100% - 40px);
}

.inputs {
  padding-right: 5px;
}

.footer {
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.calcular-button {
  height: 25px;
  width: 100px;
  border: 4px solid rgb(27, 45, 81);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: rgb(27, 45, 81);
  background-color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 10px;
}