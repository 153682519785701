.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid rgb(27, 45, 81); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  align-self: center;
  justify-self: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}