.results-container {
  width: 90%;
  align-self: stretch;
  display: grid;
  background-color: whitesmoke;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: 432px auto;
  grid-gap: 20px 4%;
  grid-template-areas:
    "charts-container1 charts-container2"
    "charts-container1 text-container";
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 40px;
}

@media (max-width: 640px) {
  .results-container {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "charts-container1" "charts-container2" "text-container";
  }
}

.charts-container1,
.charts-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px 3px #1b2d51;
  border-radius: 6px;
}

.result-column-title {
  height: 25px;
  background-color: rgb(27, 45, 81);
  border-radius: 6px 0px 0px;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-top: 7px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 3px 3px #1b2d51;
  border-radius: 6px;
  text-align: center;
  padding: 8px;
  font-size: 25px;
}

.text-container a{
  display: contents;
  position: relative;
  color: rgb(255, 167, 0);
}

.text-container {
  grid-area: text-container;
}
.charts-container1 {
  grid-area: charts-container1;
}
.charts-container2 {
  grid-area: charts-container2;
}
