input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input {
  height: 37px;
  box-sizing: border-box;
  background: white;
  outline: none;
  padding: 5px;
  border: 4px solid lightgrey;
  border-radius: 10px;
  text-align: center;
}