@font-face {
  font-family: 'FonteLogo';
  src: url("./Montserrat-ExtraBold.otf");
}

.hero-image {
  background-image: url("header-image.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 160px;
  width: auto;
  height: 350px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
}

.hero-text {
  text-align: center;
  background-color: rgb(27, 45, 81);
  opacity: 0.9;
  width: 100%;
  margin: 16px auto;
}

.title {
  color: white;
  font-size: 42px;
  opacity: 1;
  font-family: "FonteLogo";
  font-size: 50px;
}

@media only screen and (max-width: 670px) {
  .title {
    font-size: 32px;
  }
}
