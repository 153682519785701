.button {
  background-color: #1b2d51;

  -webkit-border-top-left-radius: 28px;
  -moz-border-radius-topleft: 28px;
  border-top-left-radius: 28px;

  -webkit-border-top-right-radius: 28px;
  -moz-border-radius-topright: 28px;
  border-top-right-radius: 28px;

  -webkit-border-bottom-right-radius: 28px;
  -moz-border-radius-bottomright: 28px;
  border-bottom-right-radius: 28px;

  -webkit-border-bottom-left-radius: 28px;
  -moz-border-radius-bottomleft: 28px;
  border-bottom-left-radius: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  font-family: arial;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;

  height: 35px;
  width: 35px;
  text-decoration: none;
  
  text-align: center;
  vertical-align: center;
  cursor: pointer;
}
